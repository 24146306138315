import { WETH9 } from '@pancakeswap/sdk';
import { Currency } from '@pancakeswap/swap-sdk-core';
import { LeaderboardPrizes } from 'views/Leaderboard/types';
import { prizeConfigs } from './prizes';
import { ICE } from '@pancakeswap/tokens';

interface Competition {
  id: number;
  chainId: number;
  startTime: string;
  endTime: string;
  prizes: LeaderboardPrizes;
  prizePool: {amount: number, token: Currency};
}

export const competitions: Competition[] = [
  {
    id: 1,
    chainId: 25,
    startTime: "2024-10-28T12:00:00.000Z",
    endTime: "2024-11-17T00:00:00.000Z",
    prizes: prizeConfigs[1],
    prizePool: { 
      amount: 5000, 
      token: WETH9[25]
    }
  },{
    id: 2,
    chainId: 25,
    startTime: "2024-11-17T00:00:00.000Z",
    endTime: "2024-12-16T00:00:00.000Z",
    prizes: prizeConfigs[2],
    prizePool: { 
      amount: 3500, 
      token: WETH9[25] 
    }
  },{
    id: 3,
    chainId: 25,
    startTime: "2024-12-17T00:00:01.000Z",
    endTime: "2025-01-16T00:00:00.000Z",
    prizes: prizeConfigs[3],
    prizePool: { 
      amount: 450000000, 
      token: ICE[25] 
    }
  }
 
]