
import { Tiers } from 'config/constants/trading-competition/prizes';
import { LeaderboardPrizes } from 'views/Leaderboard/types';

// Old Prize Configuration (for backward compatibility)
export const oldPrizesConfig: LeaderboardPrizes = [
  {
    rank: '1',
    tier: Tiers.GOLD,
    prize: 30,
    hasNft: true,
    nft: { amount: 1 },
  },
  {
    rank: '2',
    tier: Tiers.SILVER,
    prize: 20,
    hasNft: true,
    nft: { amount: 1 },
  },
  {
    rank: '3',
    tier: Tiers.BRONZE,
    prize: 15,
    hasNft: true,
    nft: { amount: 1 },
  },
  {
    rank: '4 ~ 5',
    tier: Tiers.PURPLE,
    prize: 15,
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '6 ~ 10',
    tier: Tiers.TEAL,
    prize: 20,
    hasNft: false,
    nft: { amount: 0 },
  },
];

// New Prize Configuration for 20 winners
export const newPrizesConfig: LeaderboardPrizes = [
  {
    rank: '1',
    tier: Tiers.GOLD,
    prize: 30,  // 1st place gets 30% of the prize pool
    hasNft: true,
    nft: { amount: 1 },
  },
  {
    rank: '2',
    tier: Tiers.SILVER,
    prize: 20,  // 2nd place gets 20% of the prize pool
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '3',
    tier: Tiers.BRONZE,
    prize: 10,  // 3rd place gets 10% of the prize pool
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '4 ~ 5',
    tier: Tiers.PURPLE,
    prize: 5,   // 4th-5th place share 5% of the prize pool
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '6 ~ 10',
    tier: Tiers.TEAL,
    prize: 7.5,  // 6th-10th place share 7.5% of the prize pool
    hasNft: false,
    nft: { amount: 0 },
  },
  {
    rank: '11 ~ 15',
    tier: Tiers.PINK,
    prize: 5,   // 11th-15th place share 5% of the prize pool
    hasNft: false,
    nft: { amount: 0 },
  },
  {
    rank: '16 ~ 20',
    tier: Tiers.LAVENDER,
    prize: 2.5,  // 16th-20th place share 2.5% of the prize pool
    hasNft: false,
    nft: { amount: 0 },
  },
];

export const prizeConfigNoNFT: LeaderboardPrizes = [
  {
    rank: '1',
    tier: Tiers.GOLD,
    prize: 30,
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '2',
    tier: Tiers.SILVER,
    prize: 20,
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '3',
    tier: Tiers.BRONZE,
    prize: 15,
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '4 ~ 5',
    tier: Tiers.PURPLE,
    prize: 15,
    hasNft: false,
    nft: { amount: 1 },
  },
  {
    rank: '6 ~ 10',
    tier: Tiers.TEAL,
    prize: 20,
    hasNft: false,
    nft: { amount: 0 },
  },
];

export const prizeConfigs = {
  1: oldPrizesConfig,
  2: newPrizesConfig,
  3: prizeConfigNoNFT,
};
