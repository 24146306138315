import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import { ButtonMenu, ButtonMenuItem, Flex, NotificationDot } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import NextLinkFromReactRouter from 'next/link'

const SubMenu: React.FC<React.PropsWithChildren> = () => {
  const { pathname } = useRouter()
  const { t } = useTranslation()
  
  const activeIndex = useMemo(() => {
    if (pathname === '/leaderboard') return 0
    if (pathname.startsWith('/leaderboard/finished')) return 1
    return 0
  }, [pathname])

  const hasFinishedContests = false // Replace with your actual logic

  return (
    <Flex justifyContent={'end'} px={3}>
      <ButtonMenu activeIndex={activeIndex} scale="sm" variant="subtle">
        <ButtonMenuItem as={NextLinkFromReactRouter} href="/leaderboard">
          {t('Live')}
        </ButtonMenuItem>
        <NotificationDot show={hasFinishedContests}>
          <ButtonMenuItem as={NextLinkFromReactRouter} href="/leaderboard/finished" id="finished-leaderboard-button">
            {t('Finished')}
          </ButtonMenuItem>
        </NotificationDot>
      </ButtonMenu>
    </Flex>
  )
}

export default SubMenu