import { Box, Flex, Text, Button, Link, Heading, OpenNewIcon } from '@pancakeswap/uikit'
import { useTheme } from '@pancakeswap/hooks'
import { styled } from 'styled-components'
import { useTranslation } from '@pancakeswap/localization'

const TextStyles = (theme) => `
  text-align: center;
  ${theme.mediaQueries.md} {
    text-align: left;
  }
`

const Container = styled(Box)<{ backgroundColor: string }>`
  padding: 47px 16px 38px 16px;
  background: ${({ backgroundColor }) => backgroundColor};
`
const BgShine = styled.div`
  position: absolute;
  bottom: 36px;
  left: 20%;
  width: 1257px;
  height: 210px;
  background-size: cover;
  z-index: 0;
`

const StyledHeading = styled(Heading)`
  ${({ theme }) => TextStyles(theme)}
  text-align: start;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.25), 0px 0px 18px rgba(161, 101, 194, 0.88), 0px 0px 49px rgba(161, 0, 255, 0.55);
`

const TopTradersBanner = () => {
  const { t } = useTranslation()
  const { isDark } = useTheme()

  return (
    <Container
      position="relative"
      overflow={'hidden'}
      backgroundColor={
        isDark
          ? 'radial-gradient(103.12% 50% at 50% 50%, #061121 0%, #08080a 100%)'
              : 'linear-gradient(340.33deg, #c1edf0 -11.09%, #eafbf7 32.51%, #ece4fb 96.59%)'
        }
    >
      <BgShine />
      <Flex
        position="relative"
        zIndex="1"
        margin="auto"
        justifyContent="space-between"
        width={['100%', '100%', '100%', '100%', '100%', '100%', '1140px']}
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row',
        ]}
      >
       {/* <Flex flexDirection="column" alignSelf="center" width={['100%', '100%', '100%', '700px']}> */} 
       <Flex flexDirection="column" alignSelf="center" width={['100%']}>
          <Text lineHeight="110%" mb="16px" bold fontSize={['32px']}>
            {t('Obsidian Leaderboard')}
          </Text>
          <Text size={'sm'} color={'textSubtle'} mb="32px" textAlign={'start'}>
            {t('The more you trade, the more you win!')}
          </Text>
          <Flex alignSelf={['center', 'center', 'center', 'auto']}>
            <Link href="/swap" external>
              <Button>{t('Trade Now')}</Button>
            </Link>
            <Link ml="12px" external href="https://wiki.obsidian.finance/contests/biweekly-leaderboard">
              <Button ml="12px" variant="secondary" external>
                {`${t('Learn More')}`}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  )
}

export default TopTradersBanner
